import React, { useState } from "react";

import mergeArray from "./services/mergeArrays";
import uploadIconToServer from "./services/uploadIconToServer";
import { useToasts } from "react-toast-notifications";

const iconMaxSize = 900000; //size en Ko( max 10 Mo)

function ImageUpload({ setIconsListing }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState([]);

  const { addToast } = useToasts();

  const submitForm = (e) => {
    setPreviewImage([]);

    e?.preventDefault();

    const formdata = new FormData();

    for (let index = 0; index < selectedFile?.length; index++) {
      const file = selectedFile[index];

      if (file?.size > iconMaxSize) {
        addToast("Icons size is too wide >" + iconMaxSize, {
          appearance: "warning",
          autoDismissTimeout: 3000,
          autoDismiss: true,
          transitionDuration: 600,
        });
        return;
      }
      formdata.append("files", file);
    }

    uploadIconToServer(formdata, addToast).then((uploadedFiles) => {
      setIconsListing((oldIconsList) => {
        return mergeArray(oldIconsList, uploadedFiles, "iconName");
      });
    });
  };

  const handleFileInput = (e) => {
    if (e?.target?.files) {
      const files = e?.target?.files;
      setSelectedFile(files);
      const filesArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );

      setPreviewImage((prevImages) => prevImages?.concat(filesArray));

      Array.from(e?.target?.files)?.map(
        (file) => URL.revokeObjectURL(file) // avoid memory leak
      );
    }
    return;
  };

  const renderPhotos = (source) => {
    return source?.map((photo) => {
      return (
        <img
          style={{ height: 30, width: 30, objectFit: "contain" }}
          src={photo}
          alt=""
          key={photo}
        />
      );
    });
  };

  return (
    <div>
      <form>
        <div>
          <input
            {...{
              type: "file",
              onChange: handleFileInput,
              multiple: true,
              accept: "image/png",
            }}
          />
        </div>
        <button onClick={submitForm}>Submit</button>
      </form>
      <p style={{ color: "red" }}>Preview Image </p>
      {previewImage?.length !== 0 ? (
        <div className="result">{renderPhotos(previewImage)}</div>
      ) : (
        <p>No Preview</p>
      )}
    </div>
  );
}

export default ImageUpload;
